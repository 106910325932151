import React from 'react';
import { Helmet } from "react-helmet";
import {
  TopSection,
  Intro,
  HowItWorks,
  Testimonials,
  OurAwards,
  LatestWorks,
  CallToAction,
} from '../../Containers/Home';

// import sectionBackgroundCloset from '../../Assets/images/home-page-closets.jpg';
// import sectionBackgroundBlinds from '../../Assets/images/blinds_bg.jpg';
// import sectionBackground from '../../Assets/images/home-page-kitchens.jpg';

import po from '../../Assets/images/po.png';
import baneerOffer from '../../Assets/images/kitchensspecoffer.gif';

// import sectionBackground from '../../Assets/images/kitchens/home/top-section-bg90.jpg';
import hiwImg1 from '../../Assets/images/kitchens/home/hiw-step1.png';
import hiwImg2 from "../../Assets/images/select_samples.png";
import hiwImg3 from '../../Assets/images/calendar.png';
import LatestWorkOne from "../../Assets/images/kitchens/our-works/gallery/4e-min.jpg";
import LatestWorkTwo from "../../Assets/images/kitchens/our-works/gallery/4ww-min.jpg";
import LatestWorkThree from "../../Assets/images/kitchens/our-works/gallery/5sa-min.jpg";
import LatestWorkFour from "../../Assets/images/kitchens/our-works/gallery/34-min.jpg";
import LatestWorkFive from "../../Assets/images/kitchens/our-works/gallery/ae-min.jpg";
import callToActionBackground from '../../Assets/images/kitchens/home/home-footer-bg.png';

import TestimonialsReviewerFour from './../../Assets/testimonials_images/pic-01 2.png';
import TestimonialsReviewerTen from './../../Assets/testimonials_images/pic-24.png';
import TestimonialsReviewerEleven from './../../Assets/testimonials_images/pic-03.png';

const urlSearchParams = new URLSearchParams(window.location.search);

function removeParam(paramName) {
  let searchParams = urlSearchParams;
  searchParams.delete(paramName);

  if (window.history.replaceState) {
    let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
    let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchString + window.location.hash;
    window.history.replaceState(null, '', newUrl);
  }
}
const handler = { isShowPromo: false };

export const CustomClosetsPage = (props) => {

  
  const handleScroll = (e) => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const promoRef = document.getElementById('promo2Elem');
    if (promoRef) {
      if (winScroll >= 70) {
        if (!handler.isShowPromo) {
          promoRef.classList.add('visible');
          handler.isShowPromo = true;
        }
      } else {
        if (handler.isShowPromo) {
          promoRef.classList.remove('visible');
          handler.isShowPromo = false;
        }
      }
    }
  };
  document.addEventListener("scroll", handleScroll);


  const topSectionProps = {
    className: 'home-section--kitchen',
    title: 'Kitchen Renovations',
    subtitle: 'Done Right!',
    // listTitle: 'Hassle Free Quote without:',
    // listItems: ['Dealing with contractors', 'Inviting strangers to your home', 'Visiting multiple showrooms', 'Wasting time!'],
    // backgroundImage: sectionBackground,
    backgroundSize: '100%'
  }

  const howItWorksItems = [
    {
      title: 'Design Your New Kitchen Online Now',
      text: 'Using our easy to use 3D Kitchen Creator you can design your new kitchen directly from your device and get an instant quote in less than 5 minutes.',
      useBackgroundImage: true,
      imgProps: {
        src: hiwImg1,
      },
    },
    {
      title: 'Materials At Your Doorstep',
      text: 'We bring all materials to your home for you to see and touch all fixtures and finishes. No more running around to different showrooms.',
      useBackgroundImage: true,
      imgProps: {
        src: hiwImg2,
      },
    },
    {
      title: 'Kitchen Renovations Start',
      text: 'Our team of highly skilled experts will commence transforming your kitchen and will be done within 3 weeks exactly the way you envisioned.',
      imgProps: {
        src: hiwImg3,
      },
    },
  ];

  const latestWorksItems = [
    LatestWorkOne,
    LatestWorkTwo,
    LatestWorkThree,
    LatestWorkFour,
    LatestWorkFive,
  ];

  const callToActionProps = {
    text: 'Speak to our renovation team for a free consultation and get your quote instantly. It all starts with one click. We guarantee a commitment to excellent to our customers including but not limited to:',
    subtitle: "Choosing AGM Renovations means guaranteed...",
    guaranteeItems: [
      'Completing our projects on time',
      'Completing our projects within budget',
      'Guaranteed 3 Week Installation ',
      '5 Year Unlimited Warranty',
      'No added or hidden costs',
      'Decades of customer satisfaction'
    ],
    backgroundImage: callToActionBackground,
    desc: <>
      Try our 3D Creator and get your new kitchen <b className="red">designed and built just for you</b> from the
      comfort of your home.
    </>
  }

  const introProps = {
    subtitle: 'Expert kitchen renovations since 1998.',
    text: "In Canada, around 97.5% of renovation projects are either finished late or over budget. Only 2.5% of projects completed properly. As one of the oldest companies in the industry, we are proudly part of this elite group, with a proven track record of finishing kitchen renovations on time and on budget.",
  }

  const testimonialsItems = [
    {
      text: `The quality of workmanship by each of the various tradesmen was amazing and we are extremely happy with the end result. They were all very professional and obviously took a great pride in the quality of their work. Every detail was taken care of, to ensure we were completely satisfied with the process from start to finish. The supervisor of the project visited regularly and made sure any issues that we may have had, were immediately taken care of. I can honestly say that we were given the impression that our complete satisfaction with the renovation was the goal of the company.`,
      name: 'Mark Morris',
      photo: TestimonialsReviewerTen,
    },
    {
      text: `We are extremely pleased with the finished product! The tradesmen were true professionals, treated us and our home with the utmost respect. The work was exceeded our expectations.....excellent attention to detail. Overall a positive experience with AGM from design to product delivery and to clean up.`,
      name: 'Angela Thompson',
      photo: TestimonialsReviewerFour,
    },
    {
      text: `The project experience was positive from the start. The team was prepared and responsive, following our first meeting. We were provided with a start date and timeline, and AGM’s team ensured everything was in place as scheduled. We appreciated the efficiency and professionalism, especially as working parents with children at home, not having to be engaged regularly for decisions. If any of the contractors encountered issues or required clarification on aspects of the project, they engaged our project manager, and we were able to focus on our own work and supporting our children. We remain confident in our decision to hire AGM, throughout the project.`,
      name: 'Max Kordonov',
      photo: TestimonialsReviewerEleven,
    }
  ]

  const newIntro = {
    countryCode: props?.data?.countryCode,
    title: "INSTANT KITCHEN DESIGN IDEAS & QUOTE",
    subtitle: "Right from the comfort of your own home. Visualize your new kitchen today!",
    text: "AT AGM Kitchen Renovations – we want to make your kitchen design and renovation process as seamless as possible. Our 3D Kitchen Creator tool allows users to select your materials without leaving your own home. Gone are the days of driving to multiple showrooms to finalize your kitchen renovations. We bring all the materials to your door so you can spend your time on more important things."
  }


    if (!window.URLSearchParams)
      (function (w) {

        w.URLSearchParams = w.URLSearchParams || function (searchString) {
          var self = this;
          self.searchString = searchString;
          self.get = function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
            if (results == null) {
              return null;
            } else {
              return decodeURI(results[1]) || 0;
            }
          };
        }

      })(window)


    const params = Object.fromEntries(urlSearchParams.entries());
    let childs = <>
            {/* <img src={po} alt="" className="home-page_po-image"/> */}
            <img className="promo2"  id="promo2Elem"  src={baneerOffer}/>
    </>;

      sessionStorage.setItem("option", 'closet_org');

      topSectionProps.className += ' closets';
      topSectionProps.title = 'Custom Closets';
      topSectionProps.noPhoto = true;
      childs = <></>;

      if (params && params.option) removeParam('option');


    console.log('topSectionProps closets', topSectionProps);

  return (
    <>
      <Helmet>
        <meta name="robots" content="index"/>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>

      <TopSection {...topSectionProps} location={props.location}>
        {childs}
      </TopSection>

      <Intro {...newIntro} isKitchenApp={true}/>
      <HowItWorks items={howItWorksItems}/>
      <Testimonials items={testimonialsItems}/>
      <OurAwards/>
      <LatestWorks items={latestWorksItems}/>
      <CallToAction {...callToActionProps} data={props.data}/>
    </>
  )
}
