import React from 'react';
import { Helmet } from "react-helmet";

import {
  TopSection,
  Content,
  ContactInfo,
  WhoWeAre,
  HowWeStarted,
  OurValues,
  Experience,
  CallToAction,
} from '../../Containers/About';

import Modal from "../../Containers/WhyAgm/Modal";
import callToActionBg from '../../Assets/images/kitchens/home/home-footer-bg.png';

const handler = {};

export const AboutUsPage = (props) => {
  let phone;
  let email;
  let locations;

  if (props.data) {

 
    phone = props.data.kitchen_phone;


    email = props.data.eamil;
    locations = props.data.location;
  }

  const callToActionProps = {
    text: `Call ${phone} today to schedule your free consultation or get started with our 3D Online Kitchen Creator Tool Today!  You have nothing to lose and our team is ready when you are to help bring your kitchen dreams to fruition.`,   
    className: 'kitchens__about-us-call-to-action',
  }

  const contentData = {
    title: "The #1 Kitchen Design & Renovation Experts",
    text: "We provide turnkey design/built kitchen renovation packages. Since 1998, AGM Renovations has created tens of thousands of unique kitchens. As highest rated renovators, we’ve long been recognized for our quality craftsmanship, customer service, and our team of experts who can turn your old kitchen into a beautiful environment."
  }

  const experienceInfo = {
    paragraph: `
      In the Home Renovation industry about 97.5% of all projects are either finished late or over budget. 
      Only 2.5% of all projects in North America are completed on time and without extra costs. 
      As one of the oldest companies in the industry, we have a proven track record of finishing kitchen renovations on time and on budget!
    `
  }

  return (
    <>
        <Helmet>
           <meta name="robots" content="noindex"/>
        </Helmet>
      <TopSection />

      <Content {...contentData} />
      <HowWeStarted />
      <WhoWeAre />
      <OurValues />
      <Experience {...experienceInfo} />

      <Modal handler={handler} />

      <ContactInfo
        phone={phone}
        email={email}
        locations={locations}
      />
      <CallToAction {...callToActionProps} />
    </>
  );
}