import React, { useState } from "react";
import { Link } from "react-router-dom";
import filesFetcher from '../Services/filesFetcher';
import CloseIcon from '@mui/icons-material/Close';

let offerAmount = null;

const isKitchenApp = process.env.REACT_APP_NAME === 'kitchens';
const isBathroomApp = process.env.REACT_APP_NAME === "bathrooms";

const getOfferData = async (setOffer) => {
  const offers = await filesFetcher.fetchPromoOffer();
  const offerData = offers.find((item) => item.value === (isKitchenApp ? "Kitchen" : isBathroomApp ? "Bathroom" : 'Basements'));

  if (offerData) {
    offerAmount = offerData.banner;
    setOffer(offerData.banner);
  }
   
};

export const Banner = () => {
    
  const [offer, setOffer] = useState(offerAmount);
  
  if (!offer) {
    getOfferData(setOffer);
    return null;
  }

  return (
    <div className="promoStamp">
      <button 
        onClick={(e)=> { 
          e.currentTarget.parentElement.style.display = 'none'; 
          e.currentTarget.parentElement.parentElement.classList.add('closedPromo'); }} 
        className="closeBtn"
      >
        <CloseIcon/>
      </button>
      <p className="topPar">
        <span>
          Save {offer.amount} on {isKitchenApp ? 'Kitchen': isBathroomApp ? "Bathroom" : 'Basement'} Renovations
        </span>
      </p>

      <p className="middleOffer desk">
        <strong>No</strong> Money Down <br/>
        <strong>No</strong> Interest <br/>
        <strong>No</strong> Payments for 1 Year*
      </p>
      <p className="middleOffer mobile">
        <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year*
      </p>


          {offer.extra 
                ? <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image desk">
                  <strong>{
                    (offer.extra.substring(0, 1) === '$') ? `Take an extra ${offer.extra} OFF your project` : offer.extra
                  }</strong>
                  </p> 
                : null 
              }
          {offer.extra 
                ? <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image mobile">
                  <strong>{
                    (offer.extra.substring(0, 1) === '$') ? `Take an extra ${offer.extra} OFF your project` : offer.extra
                  }</strong>
                  </p> 
                : null 
              }
    
          <Link sx={{ color: '#fff', fontWeight: 700 }} to="/get-started?offer=1" className="blue-dark-windows-btn">
            <div style={{ color: '#fff', fontWeight: 700 }} className="button">
              See Offer
            </div>
          </Link>

      <span className="endPart">Offer ends {offer.end}</span>
    </div>
  );
};